import {AppContext, initialAppState} from "../contexts/appContext";
import {useReducer} from "react";
import {appReducer} from "../contexts/appReducer";

type AppProviderProps = {
    children: JSX.Element
}

const AppProvider = ({children}: AppProviderProps) => {
    const [state, dispatch] = useReducer(appReducer, initialAppState)
    return (
        <AppContext.Provider value={{state, dispatch}}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider