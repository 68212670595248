import {DefaultTheme} from "styled-components";
import {fonts} from "./fonts";

const darkTheme: DefaultTheme = {
    colors: {
        background: {
            foundation: "#20212a",
            foundationSecondary: "#2B2C38"
        },
        backgroundPrimaryLowOpacity: 'rgba(25, 25, 25, 0.25)',
        colorPrimary: 'whitesmoke',
        colorSecondary: 'whitesmoke',
        colorInactive: 'rgba(200, 200, 200, 0.4)',
        colorGradient: 'linear-gradient(to right, #1CFFC8 , #6892FF);',
        colorAccent: '#00d1c9',
        lightRed: '#FE7E7E',
        button: {
            backgroundColor: '#0697AB'
        },
        skills: {
            iconColor: 'whitesmoke'
        }
    },
    fonts: fonts
}

export default darkTheme