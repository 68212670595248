import React from 'react';
import {hydrate, render} from 'react-dom';
import './index.css';
import App from './App';
import {Helmet} from 'react-helmet'
import reportWebVitals from './reportWebVitals';
import AppProvider from "./components/providers/AppProvider";
import StyledProvider from "./components/providers/StyledProvider";
import {metaNames, metaProperties, title} from './data/metatags';

const generateMetaTags = () => {
    const metaTagsArray = []
    for (let name in metaNames) {
        metaTagsArray.push(
            <meta name={name} key={name} content={metaNames[name]}/>
        )
    }

    for (let property in metaProperties) {
        metaTagsArray.push(
            <meta property={property} key={property} content={metaProperties[property]}/>
        )
    }

    return metaTagsArray
}

const rootElement = document.getElementById("root");
if (rootElement && rootElement.hasChildNodes()) {
    hydrate(
        <React.StrictMode>
            <Helmet>
                <title>{title}</title>
                {generateMetaTags().map(metaTag => metaTag)}
            </Helmet>
            <AppProvider>
                <StyledProvider>
                    <App/>
                </StyledProvider>
            </AppProvider>
        </React.StrictMode>
        , rootElement);
} else {
    render(
        <React.StrictMode>
            <Helmet>
                <title>{title}</title>
                {generateMetaTags().map(metaTag => metaTag)}
            </Helmet>
            <AppProvider>
                <StyledProvider>
                    <App/>
                </StyledProvider>
            </AppProvider>
        </React.StrictMode>, rootElement);
}

// ReactDOM.render(
//     <React.StrictMode>
//         <Helmet>
//             <title>{title}</title>
//             {generateMetaTags().map(metaTag => metaTag)}
//         </Helmet>
//         <AppProvider>
//             <StyledProvider>
//                 <App/>
//             </StyledProvider>
//         </AppProvider>
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
