import {Page, Theme} from "./appContext";

type ActionTypes = 'setTheme' | 'setPage' | 'setMenuOpen'

export type AppActions = {
    type: ActionTypes,
    payload?: any
}

const setTheme = (theme: Theme): AppActions => {
    return {
        type: 'setTheme',
        payload: theme
    }
}

const setPage = (page: Page): AppActions => {
    return {
        type: 'setPage',
        payload: page
    }
}

const setMenuOpen = (open: boolean): AppActions => {
    return {
        type: 'setMenuOpen',
        payload: open
    }
}

export {
    setTheme,
    setPage,
    setMenuOpen
}