type MetaTagsData = {
    [name: string]: string
}

export const title = "Andy Lu - Portfolio"

export const metaProperties: MetaTagsData = {
    "og:title": "Andy Lu - Full Stack Software Developer",
    "og:type": "website",
    "og:description": "Full Stack Software Developer based in Vancouver, Canada. Primarily uses ReactJS, NodeJS and Go.",
    "og:image": "https://andy-portfolio-2022.herokuapp.com/static/media/social_image.jpg",
    "og:url": "https://www.andylu.ca",
    "twitter:card": "summary",
    "twitter:title": "Andy Lu - Full Stack Software Developer",
    "twitter:image:src": "https://andy-portfolio-2022.herokuapp.com/static/media/social_image.jpg",
    "twitter:description": "Full Stack Software Developer based in Vancouver, Canada. Primarily uses ReactJS, NodeJS and Go."
}

export const metaNames: MetaTagsData = {
    "robots": "index, follow",
    "description": "Full Stack Software Developer based in Vancouver, Canada. Primarily uses ReactJS, NodeJS and Go.",
    "author": "Andy Lu",
    "keywords": "HTML, CSS, JavaScript, TypeScript, ReactJS, SQL, PostgreSQL, Node, NodeJS, React Native, Android, Web Development, Web Developer, Full Stack Developer, Software Developer, Full Stack Software Developer, Frontend Developer, Backend Developer, React Native",
}

// export const metaTagsData: MetaTagsData = {
//     "name": [],
//     "property": [],
//     "robots": "index, follow",
//     "description": "Andy Lu is a Full Stack Software Developer based in Vancouver, Canada. Primarily uses ReactJS, NodeJS and Go. Always looking for new opportunities!",
//     "author": "Andy Lu",
//     "keywords": "HTML, CSS, JavaScript, TypeScript, ReactJS, SQL, PostgreSQL, Node, NodeJS, React Native, Android, Web Development, Web Developer, Full Stack Developer, Software Developer, Full Stack Software Developer, Frontend Developer, Backend Developer, React Native",
//     "og:title": "Andy Lu - Full Stack Software Developer",
//     "og:type": "website",
//     "og:description": "Andy Lu is a Full Stack Software Developer based in Vancouver, Canada. Primarily uses ReactJS, NodeJS and Go. Always looking for new opportunities!",
//     "og:image": window.location.origin + "/static/media/socialImage.jpg",
//     "og:url": "https://www.andylu.me",
//     "twitter:card": "summary",
//     "twitter:title": "Andy Lu - Full Stack Software Developer",
//     "twitter:image:src": window.location.origin + "/static/media/socialImage.jpg",
//     "twitter:description": "Andy Lu is a Full Stack Software Developer based in Vancouver, Canada. Primarily uses ReactJS, NodeJS and Go. Always looking for new opportunities!"
// }