import styled from "styled-components";
import screenSizes from "../screenSizes";

export type Skills = {
    text: string,
    icon: JSX.Element
}

type SkillItemProps = {} & Skills

const StyledSkillItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const SkillItemText = styled.p`
  font-size: calc(${props => props.theme.fonts.sizes.sm} - 2px);
  font-family: ${props => props.theme.fonts.fontFamily.lato};
  margin-top: 10px;
  font-weight: 700;
  color: ${props => props.theme.colors.skills.iconColor};

  ${screenSizes.below480} {
    font-size: calc(${props => props.theme.fonts.sizes.sm} - 6px);
  }
`

const SkillItem = ({text, icon}: SkillItemProps) => {
    return (
        <StyledSkillItem>
            {icon}
            <SkillItemText>
                {text}
            </SkillItemText>
        </StyledSkillItem>
    )
}

export default SkillItem