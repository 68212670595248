import React from "react";
import {AppActions, setMenuOpen, setPage as setPageAction, setTheme as setThemeAction} from "./appActions";

export type Dispatch = (action: AppActions) => void

export type Theme = 'light' | 'dark'

export type Page = 'home' | 'about' | 'projects' | 'contact'

export type AppState = {
    theme: Theme,
    page: Page,
    isMenuOpen: boolean
}

type AppContextType = {
    state: AppState,
    dispatch: Dispatch
}

export const AppContext = React.createContext<{ state: AppState; dispatch: Dispatch } | undefined>(undefined)

export const initialAppState: AppState = {
    theme: 'dark',
    page: 'home',
    isMenuOpen: false
}

const useAppContext = () => {
    const context: AppContextType | undefined = React.useContext(AppContext)
    if (context === undefined) {
        throw new Error('useAppContext must be used within a AppContextProvider!')
    }

    const currentPage = context.state.page
    const setPage = (page: Page) => {
        context.dispatch(setPageAction(page))
    }

    const theme = context.state.theme
    const setTheme = (theme: Theme) => {
        context.dispatch(setThemeAction(theme))
    }

    const isMenuOpen = context.state.isMenuOpen
    const setIsMenuOpen = (open: boolean) => {
        context.dispatch(setMenuOpen(open))
    }


    return {
        theme,
        setTheme,
        currentPage,
        setPage,
        isMenuOpen,
        setIsMenuOpen
    }
}

export default useAppContext
