import styled from "styled-components"
import screenSizes from "../screenSizes"
import {experiences} from "../../../data/experience";
import { LazyMotion, domAnimation, m } from "framer-motion";
import {useEffect, useState} from "react";

const StyledExperience = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSection = styled(m.div)`
  width: 100%;

  &:not(:first-of-type) {
    margin-top: 20px;
  }
`

const StyledExperienceHeaders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledExperienceTitle = styled.p`
  font-size: ${props => props.theme.fonts.sizes.sm};
`

const StyledExperienceYears = styled.p`
  font-size: calc(${props => props.theme.fonts.sizes.sm} - 4px);
  font-weight: 300;
`

const StyledExperienceDetails = styled.ul`
  padding-left: 20px;
  font-weight: 300;
  margin-top: 10px;
`

const StyledExperienceDetail = styled.li`
  margin-bottom: 10px;
  font-size: ${props => props.theme.fonts.sizes.sm};

  ${screenSizes.below480} {
    font-size: calc(${props => props.theme.fonts.sizes.sm} - 2px);
  }
`

type ExperienceProps = {
    isInView?: boolean
}

const Experience = ({isInView = true}: ExperienceProps) => {
    const [viewed, setViewed] = useState(isInView)

    useEffect(() => {
        if (isInView && !viewed) {
            setViewed(isInView)
        }
    }, [isInView, viewed])
    return (
        <StyledExperience>
            <LazyMotion features={domAnimation}>
                {
                    experiences.map((experience, index) => {
                        return (
                            <StyledSection
                                key={experience.company.split(" ")[0]}
                                animate={{opacity: viewed ? 1 : 0, y: viewed ? 0 : 20}}
                                transition={{ease: "easeInOut", duration: 0.6}}
                            >
                                <StyledExperienceHeaders>
                                    <StyledExperienceTitle>{experience.company}</StyledExperienceTitle>
                                    <StyledExperienceYears>
                                        {
                                            `
                                        ${experience.years.from.month} ${experience.years.from.year} - ${experience.years.to ?
                                                `${experience.years.to.month} ${experience.years.to.year}` :
                                                "Present"}
                                        `
                                        }
                                    </StyledExperienceYears>
                                </StyledExperienceHeaders>
                                <StyledExperienceDetails>
                                    {
                                        experience.achievements.map((achievement, index) => (
                                            <StyledExperienceDetail
                                                key={`${experience.company.split(" ")[0]}-achievement-${index}`}>
                                                {achievement}
                                            </StyledExperienceDetail>
                                        ))
                                    }
                                </StyledExperienceDetails>
                            </StyledSection>
                        )
                    })
                }
            </LazyMotion>
        </StyledExperience>
    )
}

export default Experience