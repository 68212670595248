import styled from "styled-components";
import Page from "../UI/page/Page";
import Project from "../UI/project/Project";
import useAppContext from "../contexts/appContext";
import {useEffect, useRef} from "react";
import useIsInView from "../hooks/useIsInView";
import screenSizes from "../UI/screenSizes";
import {projects} from "../../data/projects";
import Header from "../UI/header/Header";

const StyledProjects = styled(Page)``

const StyledProjectsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledProjectsSection = styled.div`
  content: '';
  padding: 40px 10px;
  display: grid;
  grid-auto-rows: 350px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 80px;
  width: 100%;

  ${screenSizes.below1200} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Projects = () => {

    const {setPage, currentPage} = useAppContext()

    const projectsPageRef = useRef<HTMLDivElement>(null)

    const isInView = useIsInView(projectsPageRef)

    useEffect(() => {
        if (isInView && currentPage !== 'projects') {
            setPage('projects')
        }
    }, [isInView, setPage, currentPage])


    return (
        <StyledProjects pageName='projects' id='projects' ref={projectsPageRef}>
            <StyledProjectsContent>
                <Header text="Projects" isInView={isInView}/>
                <StyledProjectsSection>
                    {projects.map((project) => {
                        return (
                            <Project key={project.name} isInView={isInView} project={project}/>
                        )
                    })}
                </StyledProjectsSection>
            </StyledProjectsContent>
        </StyledProjects>
    )
}

export default Projects