import styled, {keyframes} from "styled-components";
import Page from "../UI/page/Page";
import Shape from "../UI/shapes/Shapes";
import SkillItem, {Skills} from "../UI/skill/Skill";
import useAppContext from "../contexts/appContext";
import {useEffect, useRef} from "react";
import useIsInView from "../hooks/useIsInView";
import screenSizes from "../UI/screenSizes";
import {
    CSS3Icon,
    GoIcon,
    HTML5Icon,
    JavaScriptIcon,
    NodeIcon,
    PSQLIcon,
    ReactIcon,
    TypeScriptIcon
} from "../UI/icons/Icons";
import Education from "../UI/education/Education";
import Experience from "../UI/experience/Experience";
import Header from "../UI/header/Header";
import Text from "../UI/text/Text";
import { m } from "framer-motion";

const StyledAbout = styled(Page)`
  background-color: ${props => props.theme.colors.background.foundationSecondary};
`

const CircleShape = styled(Shape)`
  position: absolute;
  right: 30%;
  top: 60px;
  z-index: 10;
  opacity: 0.4;

  ${screenSizes.below768} {
    display: none;
  }
`

const SquareShape = styled(Shape)`
  position: absolute;
  left: -10px;
  bottom: 50%;
  z-index: 10;
  opacity: 0.4;
  transform: rotate(45deg);

  ${screenSizes.below768} {
    display: none;
  }
`

const PlusShape = styled(Shape)`
  position: absolute;
  bottom: 0;
  right: 100px;
  z-index: 10;
  opacity: 0.4;
  transform: rotate(60deg);

  ${screenSizes.below768} {
    display: none;
  }
`

const StyledAboutContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
          "main misc"
          "skills skills";
  grid-gap: 60px;

  ${screenSizes.below1600} {
    display: flex;
    flex-direction: column;
  }
`

const StyledSection = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  content: '';
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${props => props.theme.colors.colorPrimary};
  position: relative;
  grid-area: main;
`

const StyledSkillSection = styled(StyledSection)`
  grid-area: skills;
`

const StyledMiscSections = styled(StyledSection)`
  grid-area: misc;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 60px
`

const StyledMiscSection = styled.div`
  height: 100%;
  width: 100%;
  content: '';
`

const StyledSkillsHeader = styled(Header)`
  width: fit-content;
  font-size: ${props => props.theme.fonts.sizes.lg};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  margin-bottom: 20px;
  
  &::before {
    width: 0;
  }

  ${screenSizes.below1000} {
    font-size: calc(${props => props.theme.fonts.sizes.lg} - 10px);
  }

  ${screenSizes.below768} {
    font-size: calc(${props => props.theme.fonts.sizes.lg} - 16px);
  }
`

const StyledSkillsList = styled(m.div)`
  padding: 40px 0;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 20px;

  ${screenSizes.below1000} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${screenSizes.below768} {
    padding: 10px 0;
  }

`

const GradientKeyframe = keyframes`
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(-360deg);
  }
`

const StyledGradientSpan = styled.span`
  font-size: inherit;
  background-image: linear-gradient(to right, #ff6a00, #ee0979);
  animation: ${GradientKeyframe} 4s infinite linear;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const StyledAboutText = styled.p`
  font-size: ${props => props.theme.fonts.sizes.sm};
  font-weight: 300;
  position: relative;
  z-index: 20;
  line-height: 150%;

  ${screenSizes.below480} {
    font-size: calc(${props => props.theme.fonts.sizes.sm} - 2px);
  }
`

const About = () => {

    const {setPage, currentPage} = useAppContext()

    const aboutPageRef = useRef<HTMLDivElement>(null)

    const isInView = useIsInView(aboutPageRef)

    useEffect(() => {
        if (isInView && currentPage !== 'about') {
            setPage('about')
        }
    }, [isInView, setPage, currentPage])

    const skills: Skills[] = [
        {
            text: 'HTML',
            icon: <HTML5Icon/>
        },
        {
            text: 'CSS',
            icon: <CSS3Icon/>
        },
        {
            text: 'JavaScript',
            icon: <JavaScriptIcon/>
        },
        {
            text: 'TypeScript',
            icon: <TypeScriptIcon/>
        },
        {
            text: 'React',
            icon: <ReactIcon/>
        },
        {
            text: 'Node',
            icon: <NodeIcon/>
        },
        {
            text: 'Go',
            icon: <GoIcon/>
        },
        {
            text: 'PostgreSQL',
            icon: <PSQLIcon/>
        },
    ]


    return (
        <StyledAbout pageName='about' id='about' ref={aboutPageRef}>
            <StyledAboutContent>
                <StyledSection>
                    <Header text="Who Am I?" isInView={isInView}/>
                    <Text isInView={isInView}>
                        I am an university graduate from Kwantlen Polytechnic University, where I received my Bachelors
                        Degree in Information Technology with specialization in Web and Application
                        Development.<br/><br/>

                        I am a mostly self-taught developer, I ventured into the software industry through web
                        development. Soon realizing that I loved creating software and began exploring the vast world of
                        software development. I have the skillset to cover both frontend and backend, specifically
                        focused on ReactJS, Node and Go.<br/><br/>

                        I’ve developed for the Web, Android, iOS and desktop applications through
                        technologies such as React, React Native and Electron. Being naturally curious, I’ve also spent
                        time with
                        Java and Kotlin for a deeper understanding of the intracacies of native Android
                        development.<br/><br/>

                        Outside of software development, I like photography and cooking. You might find me
                        wandering around historical sites and buildings, or picking up a new skill that I've always
                        wanted to learn! It is always so interesting to try new things and who knows, maybe I have a
                        talent for it.
                    </Text>
                    <SquareShape type="square" color="#44B2B9" size={50}/>
                    <CircleShape type="circle" color="#FE7E7E" size={50}/>
                    <PlusShape type="plus" color="#6176AC" size={60}/>
                </StyledSection>
                <StyledMiscSections>
                    <StyledMiscSection>
                        <Header text="Experience" isInView={isInView}/>
                        <Experience isInView={isInView}/>
                    </StyledMiscSection>
                    <StyledMiscSection>
                        <Header text="Education" isInView={isInView}/>
                        <Education isInView={isInView}/>
                    </StyledMiscSection>
                </StyledMiscSections>
                <StyledSkillSection>
                    <StyledSkillsHeader isInView={isInView}>
                        My&nbsp;<StyledGradientSpan>Superpowers</StyledGradientSpan>
                    </StyledSkillsHeader>
                    <StyledSkillsList>
                        {skills.map((skill) =>
                            <SkillItem
                                key={skill.text}
                                text={skill.text}
                                icon={skill.icon}
                            />
                        )}
                    </StyledSkillsList>
                </StyledSkillSection>
            </StyledAboutContent>
        </StyledAbout>
    )
}

export default About