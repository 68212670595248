import {RefObject, useEffect, useRef, useState} from "react";

type UseIsInViewOptions = {
    root?: Element,
    threshold?: number | Array<number>,
    rootMargin?: string,
    triggerOnce?: boolean
}

const useIsInView = (ref: RefObject<HTMLElement>, options?: UseIsInViewOptions) => {
    const observerRef = useRef<IntersectionObserver | null>(null)
    const [isInView, setIsInView] = useState(false)

    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            ([entry]) => {
                setIsInView(entry.isIntersecting)
                if (options && options.triggerOnce) {
                    observerRef.current?.disconnect()
                }
            }, {
                root: options?.root || null,
                rootMargin: options?.rootMargin || '-50% 0px -50% 0px',
                threshold: options?.threshold || [0, 0.15]
            }
        )

        if (ref && ref.current) {
            observerRef.current?.observe(ref.current!!)
        }

        return () => {
            observerRef.current?.disconnect()
        }
    }, [ref, options])

    return isInView
}

export default useIsInView