import styled from "styled-components";
import {GithubIcon, LinkIcon, Spinner} from "../icons/Icons";
import PlaceHolder from '../../../assets/placeholder.jpeg'
import screenSizes from "../screenSizes";
import {useEffect, useState} from "react";
import useAppContext from "../../contexts/appContext";
import { LazyMotion, domAnimation, m } from "framer-motion";

const StyledProject = styled(m.div)`
  position: relative;
  color: white;
  height: 100%;
  width: 100%;
  border-radius: 5px;
`

const StyledProjectImage = styled.img`
  min-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;
  opacity: 0.75;
  object-fit: cover;
  object-position: center;
  pointer-events: none;
  user-select: none;
  transition: transform 150ms ease-in-out, opacity 200ms ease-in-out;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);

  ${screenSizes.below1000} {
    opacity: 1;
  }

  ${screenSizes.below480} {
    object-position: bottom;

  }

  ${StyledProject}:hover & {
    opacity: 1;
    transform: scale(1.02);

    ${screenSizes.below480} {
      transform: none;
    }
  }
`

const StyledImagePlaceholder = styled.div`
  min-height: 100%;
  min-width: 300px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.background.foundationSecondary};
`

const StyledProjectOverlay = styled.div`
  background: rgba(43, 44, 56, 0.95);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  bottom: -30px;
  right: -20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);

  ${screenSizes.below480} {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(43, 44, 56, 0.9) 0 30%, rgba(43, 44, 56, 0.3));
  }

`

const StyledProjectName = styled.p`
  font-size: calc(${props => props.theme.fonts.sizes.md} - 3px);
  font-weight: 700;
  margin-bottom: 5px;
  transition: color 150ms ease-in-out;

  ${StyledProject}:hover & {
    color: ${props => props.theme.colors.colorAccent}
  }
`

const StyledProjectDescription = styled.p`
  font-size: calc(${props => props.theme.fonts.sizes.sm} - 4px);
  font-weight: 300;
  margin: 10px 0;
`

const StyledProjectUpper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledProjectTags = styled.div`
  display: flex;
  align-items: center;
`

const StyledProjectTag = styled.p`
  font-size: calc(${props => props.theme.fonts.sizes.sm} - 5px);
  font-weight: 300;
  background-color: rgba(0, 209, 201, 0.5);
  padding: 5px 10px;
  border-radius: 5px;

  &:not(:first-of-type) {
    margin-left: 10px;
  }
`

const StyledProjectLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledProjectLink = styled.a`
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  font-weight: 700;
  text-decoration: none;
  transition: color 150ms ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.colorAccent}
  }

  &:not(:first-of-type) {
    margin-left: 10px;
  }
`

export type ProjectData = {
    type: 'mobile' | 'code' | 'desktop'
    name: string,
    imageSrc?: string,
    description?: string,
    github?: string,
    link?: string,
    tags?: string[]
}

type ProjectProps = {
    project: ProjectData,
    isInView: boolean
}

const Project = ({project, isInView = true}: ProjectProps) => {
    const {currentPage} = useAppContext()
    const [viewed, setViewed] = useState(false)

    useEffect(() => {
        if ((isInView || currentPage === 'projects') && !viewed) {
            setViewed(true)
        }
    }, [isInView, currentPage, viewed])

    return (
        <LazyMotion features={domAnimation}>
            <StyledProject
                animate={{opacity: viewed ? 1 : 0, y: viewed ? 0 : 20}}
                transition={{ ease: "easeInOut", duration: 0.5 }}>
                <StyledProjectImage
                    src={viewed ? (project.imageSrc || PlaceHolder) : PlaceHolder}
                    alt={`${project.name} image`}/>
                <StyledProjectOverlay>
                    <StyledProjectUpper>
                        <StyledProjectName>{project.name || ''}</StyledProjectName>
                        <StyledProjectLinks>
                            {
                                project.link && (
                                    <StyledProjectLink
                                        target="_blank"
                                        href={project.link}
                                        aria-label={`${project.name} Link`}
                                    ><LinkIcon/></StyledProjectLink>
                                )
                            }
                            {
                                project.github && (
                                    <StyledProjectLink
                                        target="_blank"
                                        href={project.github}
                                        aria-label={`${project.name} Github`}
                                    ><GithubIcon/></StyledProjectLink>
                                )
                            }
                        </StyledProjectLinks>
                    </StyledProjectUpper>
                    <StyledProjectDescription>{project.description || ''}</StyledProjectDescription>
                    <StyledProjectTags>
                        {project.tags && project.tags.map((tag, index) => <StyledProjectTag
                            key={tag + index}>{tag}</StyledProjectTag>)}
                    </StyledProjectTags>
                </StyledProjectOverlay>
            </StyledProject>
        </LazyMotion>
    )
}

export default Project