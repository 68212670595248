import styled from "styled-components";
import screenSizes from "../screenSizes";

type StyledSwitchProps = {
    isOn?: boolean,
    isMenuOpen: boolean
}

const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 20px;
  position: relative;
  min-width: 100px;

  ${screenSizes.below1000} {
    border: none;
    padding: 0;
  }
`

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
`

const StyledSwitchTrack = styled.div<StyledSwitchProps>`
  width: 36px;
  height: 18px;
  content: '';
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: relative;
  background-color: ${props => props.isOn ? '#4287f5' : props.isMenuOpen ? 'rgba(200,200,200,0.5)' : 'rgba(0,0,0,0.2)'};
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
  transition: background-color 150ms ease-in-out;
  cursor: pointer;
  margin: 0 4px;
`

const StyledThumb = styled.button<StyledSwitchProps>`
  height: 14px;
  width: 14px;
  content: '';
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateX(${props => props.isOn ? '130%' : '3%'});
  margin: auto 0.5px;
  background-color: white;
  transition: transform 150ms ease-in-out;
`

type SwitchProps = {
    isOn?: boolean,
    isMenuOpen: boolean,
    onSwitch: (on: boolean) => void,
    icons?: {
        left?: JSX.Element,
        right?: JSX.Element
    }
}

const Switch = ({isOn, onSwitch, icons, isMenuOpen}: SwitchProps) => {

    const onSwitchClick = () => {
        onSwitch(!isOn)
    }

    return (
        <StyledSwitch>
            {
                icons && icons.left && (
                    <StyledIcon>{icons.left}</StyledIcon>
                )
            }
            <StyledSwitchTrack onClick={onSwitchClick} isOn={isOn} isMenuOpen={isMenuOpen}>
                <StyledThumb isOn={isOn} isMenuOpen={isMenuOpen} aria-label="Switch on/off dark mode"/>
            </StyledSwitchTrack>
            {
                icons && icons.right && (
                    <StyledIcon>{icons.right}</StyledIcon>
                )
            }
        </StyledSwitch>
    )
}

export default Switch