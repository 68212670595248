import styled from "styled-components"
import screenSizes from "../../screenSizes"
import useAppContext from "../../../contexts/appContext";

const StyledMenu = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  min-width: 100px;

  ${screenSizes.below1000} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`

type StyledHorizontalBarProps = {
    active: boolean
}

const StyledHorizontalBar = styled.div<StyledHorizontalBarProps>`
  height: 2px;
  width: 25px;
  content: '';
  background-color: ${props => props.active ? "transparent" : props.theme.colors.colorPrimary};
  transition: background-color 150ms ease-in-out;
  position: relative;

  &:before {
    transition: transform 150ms ease-in-out, background-color 150ms ease-in-out;
    height: 2px;
    width: 100%;
    content: '';
    background-color: ${props => props.active ? "white" : props.theme.colors.colorPrimary};
    position: absolute;
    top: ${props => props.active ? "0px" : "-7.5px"};
    transform: rotate(${props => props.active ? "-45deg" : "0"});
    left: 0;
  }

  &:after {
    transition: transform 150ms ease-in-out, background-color 150ms ease-in-out;
    height: 2px;
    width: 100%;
    content: '';
    background-color: ${props => props.active ? "white" : props.theme.colors.colorPrimary};
    position: absolute;
    bottom: ${props => props.active ? "0px" : "-7.5px"};
    transform: rotate(${props => props.active ? "45deg" : "0"});
    left: 0;
  }
`

const MenuButton = () => {
    const {setIsMenuOpen, isMenuOpen} = useAppContext()
    return (
        <StyledMenu onClick={() => setIsMenuOpen(!isMenuOpen)} aria-label="Mobile menu button">
            <StyledHorizontalBar active={isMenuOpen}/>
        </StyledMenu>
    )
}

export default MenuButton