type Experience = {
    company: string,
    years: {
        from: {
            month: string,
            year: number
        },
        to?: {
            month: string,
            year: number
        }
    },
    achievements: string[]
}

export const experiences: Experience[] = [
    {
        company: "LMI Technologies Inc",
        years: {
            from: {
                month: "May",
                year: 2022
            }
        },
        achievements: [
            "Working on in-house applications to streamline manufacturing teams.",
            "Created an editor to allow other sensor software developers to improve their workflows."
        ]
    },
    {
        company: "Kloak Information Technologies Inc.",
        years: {
            from: {
                month: 'February',
                year: 2020
            },
            to: {
                month: 'April',
                year: 2022
            }
        },
        achievements: [
            "Became Lead Frontend Developer and lead a team consisting of designers and a technical writer.",
            "Co-authored Kloak's proprietary patent-pending technology, Decentralized Fragmented Storage Protocol.",
            "Setup GitHub actions and CI/CD for building final application bundles resulting in saving 45 minutes each release cycle."
        ]
    },
    
]