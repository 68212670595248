import React from 'react';
import useAppContext from "./components/contexts/appContext";
import Home from "./components/pages/Home";
import Navigation from "./components/navigation/Navigation";
import styled, {createGlobalStyle} from 'styled-components';
import About from "./components/pages/About";
import Projects from "./components/pages/Projects";
import Menu from "./components/UI/mobileMenu/menu/Menu";
import Contact from "./components/pages/Contact";
import Footer from "./components/UI/footer/Footer";

const StyledApp = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

// type StyledScrollUpProps = {
//     show: boolean
// }

// const StyledScrollUp = styled.div<StyledScrollUpProps>`
//   display: flex;
//   justify-content: flex-end;
//   max-width: 2000px;
//   position: absolute;
//   left: 0;
//   bottom: -30px;
//   right: 30px;
//   color: ${props => props.theme.colors.colorPrimary};
//   opacity: ${props => props.show ? 1 : 0};
//   z-index: ${props => props.show ? 998 : -10};
//   transition: z-index 150ms ease-in-out, opacity 200ms ease-in-out;
//   padding: 0 20px;
//
//   & > * {
//     width: 60px;
//     height: 60px;
//   }
// `

type StyledGlobalStyleProps = {
    isMenuOpen: boolean
}

const GlobalStyle = createGlobalStyle<StyledGlobalStyleProps>`

  body {
    overflow: ${props => props.isMenuOpen ? 'hidden' : 'auto'};
    background-color: ${props => props.theme.colors.background.foundation};
  }
`

function App() {
    const {isMenuOpen} = useAppContext()

    return (
        <StyledApp>
            <GlobalStyle isMenuOpen={isMenuOpen}/>
            <Navigation/>
            <Menu/>
            <Home/>
            <About/>
            <Projects/>
            <Contact/>
            <Footer/>
        </StyledApp>
    );
}

export default App;
