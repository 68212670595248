import {ThemeProvider} from "styled-components";
import useAppContext from "../contexts/appContext";
import getTheme from "../theme/getTheme";

type StyledProviderProps = {
    children: JSX.Element
}

const StyledProvider = ({children}: StyledProviderProps) => {
    const {theme} = useAppContext()
    return (
        <ThemeProvider theme={getTheme(theme)}>
            {children}
        </ThemeProvider>
    )
}

export default StyledProvider