import {motion} from "framer-motion"
import styled from "styled-components"
import {Page} from "../../contexts/appContext";

type StyledItemTextProps = {
    active?: boolean
}

const StyledItem = styled(motion.li)`
  transition: color 150ms ease-in-out;
  list-style: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  height: 100%;

  &:last-of-type {
    margin-right: 10px;
  }
`

const StyledItemText = styled.p<StyledItemTextProps>`
  color: ${props => props.active ? props.theme.colors.colorPrimary : props.theme.colors.colorInactive};
  font-size: calc(${props => props.theme.fonts.sizes.sm} - 4px);
  font-weight: 700;
  position: relative;
  z-index: 10;
`

const StyledSelectedLine = styled(motion.div)`
  width: 100%;
  height: 5px;
  content: '';
  position: absolute;
  z-index: -10;
  bottom: 0;
  left: 0;
`

type ItemProps = {
    pageId: Page,
    text: string,
    selected: boolean,
    lineColor?: string,
    onClick?: (pageId: Page) => void,
    active?: boolean
}

const Item = ({pageId, text, onClick, active, selected, lineColor}: ItemProps) => {
    return (
        <StyledItem onClick={() => onClick && onClick(pageId)} layout>
            <StyledItemText active={active}>
                {text[0].toUpperCase() + text.slice(1)}
            </StyledItemText>
            {
                selected && (
                    <StyledSelectedLine
                        layoutId="line"
                        initial={false}
                        animate={{opacity: 1, backgroundColor: lineColor || "black"}}
                        transition={{
                            type: "spring",
                            stiffness: 600,
                            damping: 35
                        }}
                    />
                )
            }
        </StyledItem>
    )
}

export default Item