import {DefaultTheme} from "styled-components";
import {fonts} from "./fonts";

const lightTheme: DefaultTheme = {
    colors: {
        background: {
            foundation: "#F5F5F5",
            foundationSecondary: "#ebeef0"
        },
        backgroundPrimaryLowOpacity: "rgba(255, 255, 255, 0.4)",
        colorPrimary: '#3D3D3D',
        colorSecondary: '#252525',
        colorInactive: 'rgba(0, 0, 0, 0.4)',
        colorGradient: 'linear-gradient(to right, #2B2691 , #43C6AC);',
        colorAccent: '#00d1c9',
        lightRed: '#FE7E7E',
        button: {
            backgroundColor: '#058192'
        },
        skills: {
            iconColor: '#464646'
        }
    },
    fonts: fonts
}

export default lightTheme