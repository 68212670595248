import styled from "styled-components"
import useAppContext, {Page} from "../../../contexts/appContext";
import {scrollIntoView} from "../../../../utilities/utilities";
import {SocialBehanceIcon, SocialGithubIcon, SocialLinkedInIcon} from "../../icons/Icons";

type StyledMenuProps = {
    isMenuOpen: boolean
}

const StyledMenu = styled.div<StyledMenuProps>`
  width: 100%;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  content: '';
  position: fixed;
  opacity: ${props => props.isMenuOpen ? 1 : 0};
  z-index: ${props => props.isMenuOpen ? 998 : -100};
  background-color: ${props => props.isMenuOpen ? 'rgba(0, 0, 0, 0.95)' : 'transparent'};
  transition: background-color 300ms ease-in-out, z-index 150ms ease-in-out;
`

type MenuItemProps = {
    active: boolean
}

const MenuItem = styled.button<MenuItemProps>`
  color: white;
  opacity: ${props => props.active ? 1 : 0.5};
  background-color: transparent;
  border: none;
  font-size: calc(${props => props.theme.fonts.sizes.lg} - 8px);
  cursor: pointer;
`

const StyledSocialLinks = styled.div`
  display: flex;
  align-items: center;
  bottom: 0;
  margin-top: 50px;
`

const StyledSocialLink = styled.a`
  color: white;
  margin: 0 10px;
  transition: color 150ms ease-in-out;
`

const StyledEmailLink = styled.a`
  margin-top: 20px;
  font-size: ${props => props.theme.fonts.sizes.sm};
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  width: 200px;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  user-select: none;
  transition: color 150ms ease-in-out, border 150ms ease-in-out;
`

const Menu = () => {
    const {isMenuOpen, currentPage, setIsMenuOpen, setPage} = useAppContext()

    const pages: Page[] = ['home', 'about', 'projects', 'contact']

    const onMenuItemClick = (page: Page) => {
        scrollIntoView(page)
        setPage(page)
        setIsMenuOpen(false)
    }

    return (
        <StyledMenu isMenuOpen={isMenuOpen}>
            {
                pages.map(page =>
                    <MenuItem
                        aria-label={`Scroll to ${page}`}
                        key={`menu-${page}`}
                        onClick={() => onMenuItemClick(page)}
                        active={currentPage === page}>{page[0].toUpperCase() + page.slice(1)}
                    </MenuItem>)
            }
            <StyledSocialLinks>
                <StyledSocialLink target="_blank" href="https://www.linkedin.com/in/lu-andy/" aria-label="LinkedIn">
                    <SocialLinkedInIcon size={60}/>
                </StyledSocialLink>
                <StyledSocialLink target="_blank" href="https://www.behance.net/yilungluan8381" aria-label="Behance">
                    <SocialBehanceIcon size={60}/>
                </StyledSocialLink>
                <StyledSocialLink target="_blank" href="https://github.com/ApparentlyAndy" aria-label="Github">
                    <SocialGithubIcon size={60}/>
                </StyledSocialLink>
            </StyledSocialLinks>
            <StyledEmailLink href="mailto:yilunglu.andy@gmail.com" aria-label="Email">
                Email Me
            </StyledEmailLink>
        </StyledMenu>
    )
}

export default Menu