import {Fonts} from "./types";

export const fonts: Fonts = {
    sizes: {
        sm: '18px',
        md: '20px',
        lg: '48px',
        xlg: '72px'
    },
    fontFamily: {
        barlow: "'Barlow', sans-serif",
        lato: "'lato', cursive",
        inspiration: "'Inspiration', cursive"
    }
}