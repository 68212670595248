import {ProjectData} from "../components/UI/project/Project";
import GuardPi from '../assets/project_images/guardPi.png'
import TVMovieSearch from '../assets/project_images/tvMovieSearch.png'
import GuessTheWord from '../assets/project_images/guessTheWord.png'
import GoWebsockets from '../assets/project_images/goWebsockets.png'

export const projects: ProjectData[] = [
    {
        type: "code",
        name: "GuardPi",
        imageSrc: GuardPi,
        description: "Created as part of a graduation project while in university. The goal of this project was to see if our group could use budget Arduino microprocessors to create a home security system with multiple interchangeable devices.",
        github: 'https://github.com/ApparentlyAndy/GuardPi',
        tags: ['Arduino', 'React Native', 'MQTT']
    },
    {
        type: "code",
        name: "Go Websockets",
        imageSrc: GoWebsockets,
        description: "Websocket module written in Go. Written entirely from scratch, from reading a websocket connection request, to calculating websocket accept hash, hijacking the connection and reading individual frames of data.",
        github: 'https://github.com/ApparentlyAndy/go-websocket',
        tags: ['Go', 'Websockets']
    },
    {
        type: "mobile",
        name: "TV Movie Search",
        imageSrc: TVMovieSearch,
        description: "Android application written in Kotlin, utilizing The Movie DB API to fetch information about TV shows and Movies. Allows the users to scroll through a collection of movies and tv shows or to search using their own keywords.",
        github: 'https://github.com/ApparentlyAndy/TV-Movie-Search',
        tags: ['Android', 'Kotlin', 'API']
    },
    {
        type: "desktop",
        name: "Guess The Word",
        imageSrc: GuessTheWord,
        description: "Hangman style game created in HTML, CSS and JavaScript. This project served as a way to solidify my web development skills without the use of a library or framework.",
        github: 'https://github.com/ApparentlyAndy/GuessTheWord',
        link: 'https://guessthewordgame.herokuapp.com/',
        tags: ['HTML', 'CSS', 'JavaScript']
    }
]