import styled from "styled-components";


const StyledShape = styled.div`
`

type ShapeProps = {
    type: 'circle' | 'square' | 'plus',
    color: string,
    size?: number,
    className?: string
}

const Shape = ({type, color, size = 100, className}: ShapeProps) => {

    const getShape = () => {
        switch (type) {
            case 'circle':
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <circle cx="50" cy="50" r="37.5" stroke={color} strokeWidth="25"/>
                    </svg>
                )
            case 'square':
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect x="12.5" y="12.5" width="75" height="75" rx="17.5" stroke={color} strokeWidth="25"/>
                    </svg>
                )
            case 'plus':
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_33_15)">
                            <path
                                d="M62.504 12.5001C62.504 5.60121 56.9067 0 50.0004 0C43.1024 0 37.5049 5.60121 37.5049 12.5001V87.5002C37.5049 94.3991 43.1024 100 50.0004 100C56.9067 100 62.504 94.3991 62.504 87.5002V12.5001Z"
                                fill={color}/>
                            <path
                                d="M87.5004 62.4994C94.3993 62.4994 100 56.9021 100 49.9958C100 43.0978 94.3993 37.5003 87.5004 37.5003L12.5003 37.5003C5.60142 37.5003 0.00021004 43.0978 0.00021004 49.9958C0.00021004 56.9021 5.60142 62.4994 12.5003 62.4994L87.5004 62.4994Z"
                                fill={color}/>
                        </g>
                        <defs>
                            <clipPath id="clip0_33_15">
                                <rect width="100" height="100" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>

                )
        }
    }


    return (
        <StyledShape className={className}>
            {getShape()}
        </StyledShape>
    )
}

export default Shape