import {LazyMotion, domAnimation, m} from "framer-motion";
import styled from "styled-components";
import screenSizes from "../screenSizes";
import {ReactNode, useEffect, useState} from "react";

const StyledHeaderTitle = styled(m.h2)`
  width: fit-content;
  font-size: ${props => props.theme.fonts.sizes.lg};
  color: ${props => props.theme.colors.colorPrimary};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  margin-bottom: 20px;

  ${screenSizes.below1000} {
    font-size: calc(${props => props.theme.fonts.sizes.lg} - 10px);
  }

  ${screenSizes.below768} {
    font-size: calc(${props => props.theme.fonts.sizes.lg} - 16px);
  }

  &::before {
    width: 110%;
    height: 12px;
    content: '';
    position: absolute;
    background-color: #00d1c9;
    bottom: 2px;
    z-index: -1;
    border-radius: 2px;
    opacity: 0.6;
  }
`

type HeaderProps = {
    text?: string,
    className?: string,
    children?: ReactNode | ReactNode[],
    isInView?: boolean
}

const Header = ({text, children, className, isInView = true}: HeaderProps) => {
    const [viewed, setViewed] = useState(isInView)

    useEffect(() => {
        if (isInView && !viewed ) {
            setViewed(isInView)
        }
    }, [isInView, viewed])

    return (
        <LazyMotion features={domAnimation}>
            <StyledHeaderTitle
                className={className}
                animate={{opacity: viewed ? 1 : 0, y: viewed ? 0 : 20}}
                transition={{ ease: "easeInOut", duration: 0.3 }}
            >
                {text || children}
            </StyledHeaderTitle>
        </LazyMotion>
    )
}

export default Header