import {Page} from "../components/contexts/appContext";

const scrollIntoView = (page: Page) => {
    document.getElementById(page)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
    })
}

export {
    scrollIntoView
}