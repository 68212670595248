import styled from "styled-components";
import React, {ForwardedRef, ReactNode} from "react";
import screenSizes from "../screenSizes";

const StyledPage = styled.div`
  width: 100%;
  height: fit-content;
  background-color: ${props => props.theme.colors.background.foundation};
  transition: background-color 100ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 25px 50px;

  ${screenSizes.below1200} {
    padding: 60px 10px;
  }
`

const StyledPageName = styled.p`
  position: absolute;
  font-size: 100px;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-orientation: mixed;
  text-transform: uppercase;
  color: ${props => props.theme.colors.colorPrimary};
  font-family: ${props => props.theme.fonts.fontFamily.lato};
  opacity: 0.1;
  user-select: none;
  font-weight: 300;
  left: 0;

  ${screenSizes.below1000} {
    display: none;
  }
`

const StyledPageContent = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  max-width: 1600px;
  content: '';
  padding: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${screenSizes.below1600} {
    flex-direction: column;
  }

  ${screenSizes.below1000} {
    padding: 60px;
  }

  ${screenSizes.below768} {
    padding: 20px;
  }
`

type PageProps = {
    className?: string,
    pageName?: string,
    id?: string,
    children: ReactNode
}

const Page = React.forwardRef(({children, className, pageName, id}: PageProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
        <StyledPage className={className} id={id} ref={ref}>
            <StyledPageContent>
                <StyledPageName>{pageName}</StyledPageName>
                {children}
            </StyledPageContent>
        </StyledPage>
    )
})

export default Page