import styled from "styled-components";
import Page from "../UI/page/Page";
import useAppContext from "../contexts/appContext";
import {useEffect, useRef} from "react";
import useIsInView from "../hooks/useIsInView";
import screenSizes from "../UI/screenSizes";
import {SocialBehanceIcon, SocialGithubIcon, SocialLinkedInIcon} from "../UI/icons/Icons";
import Header from "../UI/header/Header";
import Text from "../UI/text/Text";

const StyledContact = styled(Page)`
  min-height: 75vh;
  background-color: ${props => props.theme.colors.background.foundationSecondary};
`

const StyledContactContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.colorPrimary};
`

const StyledContactText = styled(Text)`
  line-height: 150%;
  text-align: center;
  width: 600px;
  padding: 0 20px;
  word-wrap: break-word;
  margin: 20px 0 40px 0;

  ${screenSizes.below768} {
    width: 100%;
  }

  ${screenSizes.below480} {
    font-size: calc(${props => props.theme.fonts.sizes.sm} - 2px);
  }
`

const StyledContactLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledContactLink = styled.a`
  color: ${props => props.theme.colors.colorPrimary};
  margin: 0 10px;
  transition: color 150ms ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.colorAccent}
  }

  ${screenSizes.below1000} {
    &:hover {
      color: ${props => props.theme.colors.colorPrimary};
    }
  }
`

const StyledEmailLink = styled.a`
  margin-top: 20px;
  font-size: ${props => props.theme.fonts.sizes.sm};
  color: ${props => props.theme.colors.colorPrimary};
  border: 2px solid ${props => props.theme.colors.colorPrimary};
  border-radius: 5px;
  width: 200px;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  user-select: none;
  transition: color 150ms ease-in-out, border 150ms ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.colorAccent};
    border: 2px solid ${props => props.theme.colors.colorAccent}
  }
  
  ${screenSizes.below1000} {
    &:hover {
      color: ${props => props.theme.colors.colorPrimary};
      border: 2px solid ${props => props.theme.colors.colorPrimary};
    }
  }
`

const Contact = () => {

    const {setPage, currentPage} = useAppContext()

    const contactPageRef = useRef<HTMLDivElement>(null)

    const isInView = useIsInView(contactPageRef)

    useEffect(() => {
        if (isInView && currentPage !== 'contact') {
            setPage('contact')
        }
    }, [isInView, setPage, currentPage])

    return (
        <StyledContact pageName="contact" id="contact" ref={contactPageRef}>
            <StyledContactContent>
                <Header text="Contact Me" isInView={isInView}/>
                <StyledContactText isInView={isInView}>
                    Don't hesitate to contact me by email or using any of my social media links below. I could be in the
                    market for a new role or new opportunities!<br/><br/>Feel free to send me a message to provide
                    feedback, give constructive criticism on my portfolio website or just to connect!
                </StyledContactText>
                <StyledContactLinks>
                    <StyledContactLink target="_blank" href="https://www.linkedin.com/in/lu-andy/"
                                       aria-label="LinkedIn">
                        <SocialLinkedInIcon size={60}/>
                    </StyledContactLink>
                    <StyledContactLink target="_blank" href="https://www.behance.net/yilungluan8381"
                                       aria-label="Behance">
                        <SocialBehanceIcon size={60}/>
                    </StyledContactLink>
                    <StyledContactLink target="_blank" href="https://github.com/ApparentlyAndy" aria-label="Github">
                        <SocialGithubIcon size={60}/>
                    </StyledContactLink>
                </StyledContactLinks>
                <StyledEmailLink href="mailto:yilunglu.andy@gmail.com" aria-label="Email">
                    Email Me
                </StyledEmailLink>
            </StyledContactContent>
        </StyledContact>
    )
}

export default Contact