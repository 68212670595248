import {Theme} from "../contexts/appContext";
import darkTheme from "./dark";
import lightTheme from "./light";

const getTheme = (theme: Theme) => {
    if (theme === 'dark') {
        return darkTheme
    }
    return lightTheme
}

export default getTheme