import styled from "styled-components";
import Switch from "../UI/switch/Switch";
import React from "react";
import useAppContext, {Page} from "../contexts/appContext";
import {scrollIntoView} from "../../utilities/utilities";
import MenuButton from "../UI/mobileMenu/menuButton/MenuButton";
import screenSizes from "../UI/screenSizes";
import Item from "./item/Item";
import {MoonIcon, SunIcon} from "../UI/icons/Icons";
import Logo from "../UI/logo/Logo";

type StyledNavigationProps = {
    isMenuOpen: boolean
}

const StyledNavigation = styled.div<StyledNavigationProps>`
  width: 100%;
  height: 60px;
  content: '';
  background-color: ${props => props.isMenuOpen ? 'transparent' : props.theme.colors.backgroundPrimaryLowOpacity};
  backdrop-filter: blur(5px);
  position: fixed;
  padding: 0 60px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease-in-out;

  ${screenSizes.below1000} {
    height: 70px;
    padding: 0;
    justify-content: space-between;
  }
`

const StyledNavigationLinks = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
`

const StyledNavigationContent = styled.div`
  width: 100%;
  max-width: 1600px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${screenSizes.below1000} {
    display: none;
  }
`

const Navigation = () => {
    const {theme, setTheme, currentPage, isMenuOpen} = useAppContext()

    const colors = ['#ffc43d', '#0fa3b1', '#62c370', '#e65f5c', '#791e94']

    const pages: Page[] = ['home', 'about', 'projects', 'contact']

    const onNavigationItemClick = (page: Page) => {
        scrollIntoView(page)
    }

    return (
        <StyledNavigation isMenuOpen={isMenuOpen}>
            <MenuButton/>
            <Logo/>
            <StyledNavigationContent>
                <StyledNavigationLinks>
                    {pages.map((page, index) => {
                        return (
                            <Item key={`navigation-${page}-${index}`}
                                  pageId={page}
                                  text={page}
                                  onClick={onNavigationItemClick}
                                  active={currentPage === page}
                                  selected={currentPage === page}
                                  lineColor={colors[index]}/>
                        )
                    })}
                </StyledNavigationLinks>
            </StyledNavigationContent>
            <Switch
                isMenuOpen={isMenuOpen}
                isOn={theme === 'dark'}
                onSwitch={(on) => setTheme(on ? 'dark' : 'light')}
                icons={{
                    left: <SunIcon isMenuOpen={isMenuOpen}/>,
                    right: <MoonIcon isMenuOpen={isMenuOpen}/>
                }}
            />
        </StyledNavigation>
    )
}

export default Navigation