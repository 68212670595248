import styled from "styled-components";
import screenSizes from "../screenSizes";
import {ReactNode, useEffect, useState} from "react";
import { LazyMotion, domAnimation, m } from "framer-motion";

const StyledText = styled(m.p)`
  font-size: ${props => props.theme.fonts.sizes.sm};
  font-weight: 300;
  position: relative;
  z-index: 20;
  line-height: 150%;

  ${screenSizes.below480} {
    font-size: calc(${props => props.theme.fonts.sizes.sm} - 2px);
  }
`

type TextProps = {
    children: ReactNode,
    className?: string,
    isInView?: boolean
}

const Text = ({children, className, isInView = true}: TextProps) => {
    const [viewed, setViewed] = useState(isInView)

    useEffect(() => {
        if (isInView && !viewed) {
            setViewed(isInView)
        }
    }, [isInView, viewed])

    return (
        <LazyMotion features={domAnimation}>
            <StyledText
                className={className}
                animate={{opacity: viewed ? 1 : 0, y: viewed ? 0 : 20}}
                transition={{ease: "easeInOut", duration: 0.5}}
            >
                {children}
            </StyledText>
        </LazyMotion>
    )
}

export default Text