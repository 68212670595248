import {AppActions} from "./appActions";
import {AppState} from "./appContext";

export const appReducer = (state: AppState, action: AppActions): AppState => {
    switch (action.type) {
        case 'setTheme':
            return {
                ...state,
                theme: action.payload
            }
        case 'setPage':
            return {
                ...state,
                page: action.payload
            }
        case 'setMenuOpen':
            return {
                ...state,
                isMenuOpen: action.payload
            }
        default:
            console.log("I RAN!")
            return state;
    }
}