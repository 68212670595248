import styled from "styled-components"

const StyledFooter = styled.footer`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.colors.colorPrimary};
`

const StyledFooterContent = styled.p`
  max-width: 1600px;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: calc(${props => props.theme.fonts.sizes.sm} - 4px);
`

const StyledFooterLink = styled.a`
  margin-left: 3px;
  color: ${props => props.theme.colors.colorPrimary};

  &:visited {
    color: ${props => props.theme.colors.colorPrimary}
  }
`

const Footer = () => {
    return (
        <StyledFooter>
            <StyledFooterContent>
                © {new Date().getFullYear()}. Built by Andy Lu using <StyledFooterLink target="_blank"
                                                                                       href="https://reactjs.org/"
                                                                                       aria-label="ReactJS Link">React</StyledFooterLink>.
            </StyledFooterContent>
        </StyledFooter>
    )
}

export default Footer