import styled from "styled-components";
import Page from "../UI/page/Page";
import Shape from "../UI/shapes/Shapes";
import Lottie from 'react-lottie-player'
import CoderAnimation from '../../assets/lottie/lottie_programmer.json'
import {useEffect, useRef, useState} from "react";
import useIsInView from "../hooks/useIsInView";
import useAppContext from "../contexts/appContext";
import screenSizes from "../UI/screenSizes";
import {scrollIntoView} from "../../utilities/utilities";
import { LazyMotion, domAnimation, m } from "framer-motion";

const StyledHome = styled(Page)`
  min-height: 100vh;
  position: relative;
`

const CircleShape = styled(Shape)`
  position: absolute;
  top: -6px;
  left: -14px;
  z-index: -10;
  opacity: 0.8;

  ${screenSizes.below768} {
    opacity: 0;
  }
`

const StyledSectionLeft = styled(m.div)`
  width: 100%;
  height: 100%;
  flex: 1;
  content: '';
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.colors.colorPrimary};
  padding: 0 20px 0 0;

  ${screenSizes.below1600} {
    padding: 0;
  }
`

const StyledSectionRight = styled(StyledSectionLeft)`
  padding: 0 0 0 20px;
  flex: 0.8;

  ${screenSizes.below1600} {
    display: none;
  }
`

const StyledHomeHeader = styled.h1`
  font-size: ${props => props.theme.fonts.sizes.lg};
  position: relative;
  z-index: 11;

  ${screenSizes.below1000} {
    font-size: calc(${props => props.theme.fonts.sizes.lg} - 10px);
  }

  ${screenSizes.below480} {
    font-size: calc(${props => props.theme.fonts.sizes.lg} - 20px);
  }
`

const StyledGradientSpan = styled.span`
  font-size: inherit;
  background-image: ${props => props.theme.colors.colorGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const StyledHeaderTextSpan = styled.span`
  font-size: inherit;

  ${screenSizes.below768} {
    display: none;
  }
`

const StyledHomeText = styled.p`
  font-size: ${props => props.theme.fonts.sizes.md};
  line-height: 150%;
  font-weight: 300;
  margin-top: 10px;
  max-width: 900px;

  ${screenSizes.below480} {
    font-size: calc(${props => props.theme.fonts.sizes.sm} - 2px);
  }
`

const StyledCTAButton = styled.button`
  margin-top: 25px;
  width: 150px;
  height: 50px;
  border-radius: 50px;
  border: none;
  color: white;
  background-color: ${props => props.theme.colors.button.backgroundColor};
  font-size: ${props => props.theme.fonts.sizes.sm};
  transition: transform 200ms ease-in-out, box-shadow 150ms ease-in-out;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }
`


const Home = () => {

    const [viewed, setViewed] = useState(false)

    const getProfessionalYears = () => {
        const currentDate = new Date()
        return `${currentDate.getFullYear() - 2020}` + `${12 - currentDate.getMonth() - 2 >= 6 ? '.5' : ''}`
    }

    const {setPage, currentPage} = useAppContext()

    const homePageRef = useRef<HTMLDivElement>(null)

    const isInView = useIsInView(homePageRef)

    useEffect(() => {
        if (isInView && currentPage !== 'home') {
            setPage('home')
        }

        if (isInView && !viewed) {
            setViewed(true)
        }
    }, [isInView, setPage, currentPage, viewed])

    return (
        <StyledHome pageName='home' ref={homePageRef} id='home'>
            <LazyMotion features={domAnimation}>
                <StyledSectionLeft
                    animate={{opacity: viewed ? 1 : 0, y: viewed ? 0 : 20}}
                    transition={{ease: "easeInOut", duration: 0.3}}
                >
                    <StyledHomeHeader>Hello, I'm Andy Lu<br/>
                        <CircleShape type='circle' color='#FE7E7E' size={40}/>
                        <StyledHeaderTextSpan>
                            I am a {' '}
                        </StyledHeaderTextSpan>
                        <StyledGradientSpan>
                            Full Stack Developer
                        </StyledGradientSpan>
                    </StyledHomeHeader>
                    <StyledHomeText>
                        I'm a software developer with {getProfessionalYears()} years of professional experience.
                        Passionate in all things technology, willing to push my boundaries
                        and tackle new problems. Always on the lookout for knowledge and
                        new opportunities to hone my skills and broaden my skillset.
                    </StyledHomeText>
                    <StyledCTAButton onClick={() => scrollIntoView('contact')} aria-label="Reach out/Contact me">
                        Reach Out
                    </StyledCTAButton>
                </StyledSectionLeft>
            </LazyMotion>
            <StyledSectionRight>
                <Lottie animationData={CoderAnimation}
                        play
                        style={{width: '90%', height: '90%'}}/>
            </StyledSectionRight>
        </StyledHome>
    )
}

export default Home