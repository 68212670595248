import styled from "styled-components"
import useAppContext from "../../contexts/appContext";
import AndyLogoDark from '../../../assets/andy_logo_dark.svg'
import AndyLogoLight from '../../../assets/andy_logo_light.svg'

const StyledLogo = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 14px 0;
`

const Logo = () => {
    const {isMenuOpen, theme} = useAppContext()

    return (
        <StyledLogo src={
            isMenuOpen || theme !== 'light' ? AndyLogoLight : AndyLogoDark
        } alt="Andy Lu Logo"/>
    )
}

export default Logo